import { Component, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { instanceToInstance } from 'class-transformer';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { Company } from '../../../models/company.model';
import { GroupBy } from '../../../models/group-by.model';
import { Product } from '../../../models/product.model';
import { CurrentDateService } from '../../../services/current-date.service';
import { DataDogLoggerService } from '../../../services/data-dog-logger.service';
import { defaultOrderExpiration } from '../../../utilities/date';
import { Order } from '../../modules/commercial/models/order.model';
import { OrderService } from '../../modules/commercial/services/order.service';
import { qualityToString } from '../../pipes/quality.pipe';

@Component({
  selector: 'ag-republish-massively',
  exportAs: 'agRepublishMassively',
  templateUrl: './republish-massively.component.html',
  styleUrls: ['./republish-massively.component.scss']
})
export class RepublishMassivelyComponent implements OnDestroy {

  @ViewChild('RepublishMassively', { static: true }) private readonly template: TemplateRef<any>;

  @Input() public company: Company;

  @Output() readonly reloadMyOperations = new EventEmitter();

  public ordersByProduct: GroupBy<Product, Order>[] = [];
  public ordersByProductOriginal: GroupBy<Product, Order>[] = [];
  /** Flag used to indicate if the component is loading information. */
  public loading: boolean;
  public selection: Order[] = [];
  public republishing: boolean = false;
  public editPrices: boolean;
  public dates: string[];
  public hasDates: boolean;

  private modalRef: BsModalRef;
  private modalSub: Subscription;
  private subscriptions: Subscription[] = [];

  constructor(
    private orderService: OrderService,
    private modalService: BsModalService,
    private currentDate: CurrentDateService,
    private dataDogLoggerService: DataDogLoggerService
  ) { }

  private loadData(): void {
    this.loading = true;

    this.subscriptions.push(this.orderService.getDateToRepublish(this.company.id).subscribe(dates => {
      this.dates = dates;
      this.hasDates = this.dates.length > 0;

      if (this.hasDates) this.onChangeDate(this.dates[0]);
      else this.loading = false;
    }));
  }

  public open(): void {
    this.loadData();
    this.openModal(this.template, 'modal-lg');
  }

  private parseOrdersResponse(ordersByProduct: GroupBy<Product, Order>[]): void {
    this.ordersByProduct = instanceToInstance(ordersByProduct);

    // TODO: Mover quality string a otro lado
    this.ordersByProduct.forEach(group => {
      group.values.forEach(order => {
        order.quality_string = qualityToString(order.product_detail.quality, group.key);
      });
    });

    this.loading = false;
  }

  private convertDate(inputFormat: Date): string {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    const d = new Date(inputFormat);
    return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-');
  }

  public republish(): void {
    this.republishing = true;

    const data = [];
    this.selection.forEach(order => {
      let date = defaultOrderExpiration(this.company, order, false, this.currentDate);

      data.push({
        'id': order.id,
        'date_from': this.convertDate(order.business_detail.delivery.date_from),
        'date_to': this.convertDate(order.business_detail.delivery.date_to),
        'business_detail': {
          'price': {
            'value': order.business_detail.price.value
          }
        },
        'expiration_datetime': date
      });
    });

    this.subscriptions.push(this.orderService.republish(this.company.id, data).subscribe({
      next: response => {
        this.republishing = false;
        this.closeModal();
        this.reloadMyOperations.emit();
      },
      error: error => {
        this.republishing = false;
        this.closeModal();
        this.dataDogLoggerService.warn(error.message, error.error);
      }
    }));
  }

  private cleanSelection(): void {
    this.selection = Array();
  }

  public onChangeDate(date: string): void {
    this.loading = true;

    this.cleanSelection();

    this.subscriptions.push(this.orderService.getOrdersToRepublish(this.company.id, date).subscribe(ordersByProduct => {
      this.parseOrdersResponse(ordersByProduct);
      this.loading = false;
    }));
  }

  // TODO: This should be a pipe
  public countryAndPort(order: Order): string {
    return order.product.attributes.country.name + ' / ' + order.business_detail.port.name;
  }

  public toogleEditPrices(): void {
    this.editPrices = !this.editPrices;
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      this.republishing = false;
      this.editPrices = false;
      this.cleanSelection();
      // this.processing = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
