import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { instanceToInstance } from "class-transformer";
import deepEqual from 'deep-equal';
import { QuillToolbarConfig } from 'ngx-quill';
import { Subscription } from 'rxjs';

import { Company } from '../../../../../models/company.model';
import { Currency } from '../../../../../models/currency.model';
import { Location } from '../../../../../models/location.model';
import { Price } from '../../../../../models/price.model';
import { CompanyService } from '../../../../../services/company.service';
import { CurrentDateService } from '../../../../../services/current-date.service';
import { MarketService } from '../../../../../services/market.service';
import { PricePipe } from '../../../../../ui/pipes/price.pipe';
import { startOfDay } from '../../../../../utilities/date';
import { genCropList } from '../../../../../utilities/setProp';
import { qualityToString } from '../../../../pipes/quality.pipe';
import { ContractClausesFormComponent } from '../../../imported-data/components/contract-clauses-form/contract-clauses-form.component';
import { Negotiation } from '../../models/negotiation.model';
import { Order } from '../../models/order.model';
import { Proposal } from '../../models/proposal.model';

/**
 * ProposalDetail it is a component that allows to display or edit all the
 * terms of a [[Proposal]].
 *
 * ## Usage
 * ``` html
 * <proposal-detail
 * [proposal]="order"
 * [order]="order"
 * negotiableMode="visible"
 * [editable]="false"
 * [formMode]="false"
 * [previousContract]="order.product_clauses"
 * [hideUndefined]="true"
 * [condensed]="true"></proposal-detail>
 * ```
*
* ### Related UI components:
* - [[CounterOrderComponent|counter-order]]
* - [[ViewOrderComponent|view-order]]
*/
@Component({
  selector: 'proposal-detail',
  exportAs: 'proposalDetail',
  templateUrl: './proposal-detail.component.html',
  styleUrls: ['./proposal-detail.component.scss']
})
export class ProposalDetailComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @ViewChild('proposalForm', { static: true }) public readonly form: NgForm;
  @ViewChild('contract') private readonly contract: ContractClausesFormComponent;

  /** Shows a more compact version of the component. */
  @Input() public condensed: boolean;
  @Input() public negotiation: Negotiation;
  /**
   * Allows you to hide fields.
   *
   * *Not all fields are supported yet.*
   */
  @Input() public excludeFields: Array<string> = [];
  @Input() public proposal: Proposal;
  /**
   * Allows component to highlight differences between [[Proposal|Proposals]].
   */
  @Input() public previousProposal: Proposal;
  @Input() public previousContract: any;
  @Input() public order: Order;
  /**
   * Specifies whether to show which terms are negotiable and which are not.
   *
   * Possible values: ``visible``, ``editable`` or ``hidden``.
   *
   * ### Related UI components:
   * - [[NegotiableGroupComponent|negotiable-group]]
   */
  @Input() public negotiableMode: string = 'visible';
  /**
   * If the component is in form mode, sets whether the fields can be edited
   * or not.
   */
  @Input() public editable: boolean;
  /**
   * Determines from the perspective of the current [[Company]] whether it is
   * an incoming or outgoing [[Proposal]].
   *
   * Possible values: ``incoming`` or ``outgoing``.
   *
   * ### Related UI components:
   * - [[NegotiableGroupComponent|negotiable-group]]
   */
  @Input() public direction: string;
  /** Enables form mode. */
  @Input() public formMode: boolean;
  @Input() public chambers: Location[] = [];
  /** Whether this proposal is an amendment or not */
  @Input() public isAmendment: boolean;
  /**
   * Hides undefined fields.
   *
   * *Ignored un form mode.*
   */
  @Input() public hideUndefined: boolean;
  /** Disables all negotiable switches */
  @Input() public disableAllSwitches: boolean;

  @Output() readonly onInput = new EventEmitter();

  /**
   * Identify which fields were adjusted by the system to inform the user.
   *
   * Any other change will be interpreted as a modification proposed by the
   * counterparty.
   */
  public adjustedFields: any = {};
  public snapshotProposal: Proposal;
  public deliveryMinDate: Date;
  public company: Company;
  /** [[Market]] supported [[Currency|Currencies]]. */
  public currencies: Currency[] = [];

  /** @ignore */
  public contractHasChanged: boolean = false;

  /** @ignore */
  public lastStateOfContract: boolean = false;

  /** @ignore */
  public isNew: boolean = false;

  // Media gallery variables
  /** @ignore */
  public active_media: number = 0; // Default active media
  /** @ignore */
  public proposal_media: Array<any> = [];
  /** @ignore */
  public media_fullscreen: boolean;
  /** List of crop years. */
  public cropList: string[];

  /** The language currently used. */
  public currentLang: string;
  public toolbarOptions: QuillToolbarConfig = [
    ['bold', 'italic', 'underline'],        // toggled buttons
    // ['blockquote', 'code-block'],

    // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    // [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    // [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    // [{ 'direction': 'rtl' }],                         // text direction

    // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    // [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    // [{ 'font': [] }],
    // [{ 'align': [] }],
    ['link'],                         // link and image, video

    ['clean']                                         // remove formatting button
  ];

  private subscriptions: Subscription[] = [];
  private today_start: Date;
  private deepEqual: any = deepEqual;

  /** @ignore */
  constructor(
    private marketService: MarketService,
    private companyService: CompanyService,
    private currentDate: CurrentDateService,
    private translateService: TranslateService
  ) {
    this.today_start = startOfDay(this.currentDate.get()); // Set to zero to avoid false positives when the start date is today
    this.cropList = genCropList();
  }

  /** @ignore */
  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang === 'es' ? undefined : this.translateService.currentLang;

    this.subscriptions.push(this.companyService.watch().subscribe(company => {
      if (!company) return;

      this.company = company;

      this.subscriptions.push(this.marketService.watchCurrencies().subscribe(currencies => {
        this.currencies = currencies;
      }));

      this.checkClauses(this.company.market.configuration.order.contract.type);

      this.snapshotProposal = instanceToInstance(this.proposal);

      // Has media?
      if (this.proposal.media) {
        for (let i = 0; i < this.proposal.media.length; i++) {
          this.proposal_media.push(this.proposal.media[i]);
        }
      }
      if (this.proposal.new_media) {
        this.parseFileList(this.proposal.new_media);
      }

      // this.subscriptions.push(this.form.statusChanges.subscribe(
      //   result => this.onInput.emit(this.form)
      // ));
    }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.checkFromDate();
  }

  private _originalDeliveryDate: [Date, Date];

  private checkFromDate(): void {
    const dateAdjustable = this.editable && // If form mode felds are enabled
      !this.proposal.negotiability.business_detail.delivery.range && // Delivery period is negotiable
      !this.isAmendment;

    if (dateAdjustable) {
      const { delivery } = this.proposal.business_detail;

      if (!this._originalDeliveryDate) this._originalDeliveryDate = [
        new Date(delivery.date_from.getTime()),
        new Date(delivery.date_to.getTime())
      ];

      if (delivery.date_from.getTime() < this.today_start.getTime()) {
        const diff = delivery.date_to.getTime() - delivery.date_from.getTime();
        delivery.date_from.setTime(this.today_start.getTime());
        this.adjustedFields.deliveryDate = true;

        if (delivery.date_to.getTime() < delivery.date_from.getTime()) { // Check if the to date is lower than from date
          delivery.date_to.setTime(delivery.date_from.getTime() + diff);
        }
      };

      this.deliveryMinDate = this.isAmendment ? null : this.today_start;
    } else if (this.adjustedFields.deliveryDate) {
      // Restore
      this.proposal.business_detail.delivery.range = this._originalDeliveryDate;
    }
  }

  /** @ignore */
  public hideSwitches(): boolean {
    return this.disableAllSwitches ||
      // !this.editable ||
      this.negotiableMode !== 'editable' || // If not editable mode
      (this.order.type && [4, 5].indexOf(this.order.type.id) !== -1) || // Is an Auction
      this.isAmendment;
  }

  /** @ignore */
  ngAfterViewInit(): void {
    this.subscriptions.push(this.form.statusChanges.subscribe(
      result => this.onInput.emit(this.form)
    ));
  }

  private checkClauses(contractType: string): void {
    if (contractType === "contract_clauses") {
      this.lastStateOfContract = this.contractHasChanged = !(this.deepEqual(this.previousContract, this.proposal.contract_detail));

      // Only if it has Contract
      this.subscriptions.push(this.form.valueChanges.subscribe((change) => {
        if (this.contract) this.contract.refresh();
      }));

    }
  }

  /**
   * Customizes the default Angular option comparison algorithm
   * @ignore */
  public compareId(a: { id: string | number }, b: { id: string | number }): boolean {
    return (!a && !b) || (a && b && a.id === b.id);
  }

  /**
   * Customizes the default Angular option comparison algorithm
   * @ignore */
  public comparePayment(a, b): boolean {
    return (!a && !b) || (
      a && b &&
      a.payment_condition && b.payment_condition && a.payment_condition.id === b.payment_condition.id &&
      (a.payment_condition.id !== 2 || (a.date && b.date && a.date.getTime() === b.date.getTime())) &&
      (a.payment_condition.id !== 3 || a.other === b.other)
    );
  }

  /**
   * Customizes the default Angular option comparison algorithm
   * @ignore */
  public compareString(a, b): boolean {
    return (!a && !b) || (a === b);
  }

  /**
   * Customizes the default Angular option comparison algorithm
   * @ignore */
  public compareQuality(a, b): boolean {
    if (!a && !b) {
      return true;
    } else if (!a || !b) {
      return false;
    } else {
      for (let i in a) {
        if (typeof (a[i].id) != "undefined" && typeof (b[i].id) != "undefined") {
          if (a[i].id !== b[i].id) {
            return false;
          }
        } else {
          if (a[i] !== b[i]) {
            return false;
          }
        }
      }
      for (let i in b) {
        if (typeof (a[i].id) != "undefined" && typeof (b[i].id) != "undefined") {
          if (a[i].id !== b[i].id) {
            return false;
          }
        } else {
          if (a[i] !== b[i]) {
            return false;
          }
        }
      }
      return true;
    }
  }

  /** @ignore */
  public displayName(a): string {
    return a.name;
  }

  /** @ignore */
  public displayPayment(a): string {
    let datePipe = new DatePipe('es-AR');

    if (a.payment_condition && a.payment_condition.id === 3) {
      return a.other;
    } else {
      return (a.payment_condition && a.payment_condition.name) +
        (a.payment_condition && a.payment_condition.id === 2 ? ' (' + datePipe.transform(a.date, "dd/MM/yy") + ')' : '');
    }
  }

  /** @ignore */
  public displayQuality(a: any): string {
    let str = qualityToString(a, this.order.product);
    return str || '-';
  }

  /**
   * Customizes the default Angular option comparison algorithm
   * @ignore */
  public compareDateRange(a, b): boolean {
    return (!a && !b) ||
      (
        a && b && a[0] && a[1] && b[0] && b[1] &&
        a[0].getTime() === b[0].getTime() &&
        a[1].getTime() === b[1].getTime()
      );
  }

  /** @ignore */
  public dateRangeToString(a): string {
    // TODO: LOCALE HARDCODEADO! Cuando actualizemos a angular 6 usar formatDate.
    let datePipe = new DatePipe('es-AR');
    return datePipe.transform(a[0], "dd/MM/yy") + ' - ' + datePipe.transform(a[1], "dd/MM/yy");
    //return a[0] && a[1] && formatDate(a[0], "dd/MM/yy") + ' ' + formatDate(a[1], "dd/MM/yy");
  }

  /** @ignore */
  public priceToString(price: Price): string {
    let pricePipe = new PricePipe();
    return pricePipe.transform(price);
  }

  /**
   * Contract clause change
   * @ignore */
  public onNotify(event): void {
    this.isNew = event.isNew;
    this.contractHasChanged = event.contractHasChanged > 0;
    if (!this.contractHasChanged && this.lastStateOfContract)
      this.contractHasChanged = true;
  }

  /**
   * Media explorer
   *  @ignore */
  public switchMedia(n?: number, f?: number): void {
    // Image gallery
    if (n == undefined) {
      // Shows next image
      n = this.active_media + 1;
      if (n >= this.proposal_media.length) n = 0;
    }
    this.active_media = n;
  }

  /**
   * Media explorer
   *  @ignore */
  public toggleFullscreen(e?: Event): void {
    if (e) e.stopImmediatePropagation();

    if (window.innerWidth < 992) {
      this.media_fullscreen = false;
      this.switchMedia();
    } else this.media_fullscreen = !this.media_fullscreen;
  }

  /**
   * Media explorer
   *  @ignore */
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.media_fullscreen) {
      event.stopImmediatePropagation();
      switch (event.key) {
        case 'Escape':
          this.toggleFullscreen();
          break;
        case 'ArrowLeft':
          this.previous();
          break;
        case 'ArrowUp':
          this.previous();
          break;
        case 'ArrowRight':
          this.next();
          break;
        case 'ArrowDown':
          this.next();
          break;
      };
    }
  }

  /**
   * Media explorer
   *  @ignore */
  public next(e?: Event): void {
    // Navigates to the next media
    if (e) e.stopImmediatePropagation();
    if (this.active_media < this.proposal_media.length - 1) this.active_media++;
    else this.active_media = 0;
  }

  /**
   * Media explorer
   *  @ignore */
  public previous(e?: Event): void {
    // Navigates to the previous media
    if (e) e.stopImmediatePropagation();
    if (this.active_media > 0) this.active_media--;
    else this.active_media = this.proposal_media.length - 1;
  }

  private parseFileList(files: FileList): void {
    const imageType = /image.*/;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (!file.type.match(imageType)) {
        continue;
      }

      const reader = new FileReader();
      reader.onload = (function (arr) {
        return function (e) {
          arr.push({
            uri: e.target.result
          });
        };
      })(this.proposal_media);
      reader.readAsDataURL(file);
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
