<div class="container" [class.hidden]="previewOn || fatalError">
  <div class="content-box">
    <div class="ag-header sticky">
      <div class="ag-header-title">
        <h3 *ngIf="!editMode">{{ (isAuction ? 'AUCTION_FORM.HEADING' : 'ORDER_FORM.HEADING')|translate }}</h3>
        <h3 *ngIf="editMode">
          <span>
            {{ 'ORDER_FORM.EDITING'|translate }}
            <a href routerLink="../../order/{{ order.id }}" title="{{ order.company.name }}">{{ 'GLOBAL.ORDER'|translate }} #{{ order.id }}</a>
          </span>
        </h3>
      </div>
      <div class="ag-header-actions">
        <div class="btn-toolbar">
          <div class="btn-group">
            <button type="button"
            class="btn btn-link" (click)="cancel()" [disabled]="sending">
              {{ 'GLOBAL.CANCEL'|translate }}
            </button>
          </div>
          <div class="btn-group">
            <button class="btn btn-agree" [disabled]="!form.valid || sending || isLoading()" type="submit"
            form="create-order">
              <i class="material-symbols-rounded hidden-xs hidden-sm">remove_red_eye</i>
              {{ 'ORDER_FORM.PREVIEW'|translate }}...
            </button>
          </div>
        </div>
      </div>
    </div>
    <form id="create-order" (ngSubmit)="form.valid && showPreview()" #form="ngForm">
      <div class="row step"
      [validateFormStep]="!orderType.invalid &&
      order.scope &&
      !broker.invalid &&
      !fetchingLocations &&
      !delivery_type.invalid  &&
      !(company?.market.configuration.location.enabled && order.operation_type === 'compra' && !locations.length) &&
      (!isAuction || (order.auction.date_from && order.auction.date_to && !auction_hour.invalid))"
      #step01="validateFormStep"
      [nextStep]="step02">
        <div class="col-xs-12">
          <div class="line"></div>
          <span class="step-indicator">
            <span></span>
            <i class="material-symbols-rounded">done</i>
          </span>
          <div class="container-fluid" style="padding-left: 0px;">
            <div class="row"><!-- Order type -->
              <div class="col-md-6">
                <fieldset [disabled]="mergeMode || editMode || !company?.activity.buyer || !company?.activity.seller">
                  <div class="form-group" hasError>
                    <label class="control-label">{{ 'GLOBAL.TYPE'|translate }}</label>
                    <span class="small help-block" *ngIf="company?.activity && (!company?.activity.buyer || !company?.activity.seller)">
                      <span *ngIf="!isAuction">
                        {{ 'ORDER_FORM.TYPE.HELP_0'|translate }}
                        <span *ngIf="!company?.activity.seller" class="buy">{{ 'GLOBAL.BID'|translate }}</span>
                        <span *ngIf="!company?.activity.buyer" class="sell">{{ 'GLOBAL.SELL'|translate }}</span>
                        {{ 'ORDER_FORM.TYPE.HELP_1'|translate }}
                      </span>
                      <span *ngIf="isAuction">
                        {{ 'AUCTION_FORM.TYPE.HELP_0'|translate }}
                        <span *ngIf="!company?.activity.seller" class="buy">{{ 'GLOBAL.BID'|translate }}</span>
                        <span *ngIf="!company?.activity.buyer" class="sell">{{ 'GLOBAL.SELL'|translate }}</span>
                        {{ 'AUCTION_FORM.TYPE.HELP_1'|translate }}
                      </span>
                    </span>
                    <div class="btn-group btn-group-agree btn-group-justified" data-toggle="buttons">
                      <label class="btn btn-default" [class.active]="order.operation_type === 'compra'">
                        <input type="radio" name="orderType" id="order-type-1" [ngModel]="order.operation_type"
                          (ngModelChange)="changeOperationType($event)" [value]="'compra'" required #orderType="ngModel">
                        <span>{{ 'GLOBAL.BID'|translate }}</span>
                      </label>
                      <label class="btn btn-default" [class.active]="order.operation_type === 'venta'">
                        <input type="radio" name="orderType" id="order-type-2" [ngModel]="order.operation_type"
                          (ngModelChange)="changeOperationType($event)" [value]="'venta'">
                        <span>{{ 'GLOBAL.SELL'|translate }}</span>
                      </label>
                    </div>
                    <span class="small help-block">
                      <ng-container *ngIf="isAuction; else notAuction">
                        <!-- It's an auction -->
                        <span *ngIf="order.operation_type === 'compra'" [innerHtml]="'AUCTION_FORM.TYPE.BUY_HELP'|translate"></span>
                      </ng-container>
                      <ng-template #notAuction>
                        <!-- It's NOT an auction -->
                        <ng-container *ngIf="!company?.market.configuration.order.allow_negotiation_request &&
                        company?.market.configuration.transactions.enabled">
                          <!-- NO negotiation request -->
                          <ng-container *ngIf="order.operation_type === 'compra'">
                            <!-- It's buying -->
                            <span *ngIf="company?.market.configuration.order.pre_book.buy" [innerHtml]="'ORDER_FORM.TYPE.PREBOOK_HELP'|translate:{class:'buy', type:('GLOBAL.BID'|translate)}"></span>
                            <span *ngIf="!company?.market.configuration.order.pre_book.buy" [innerHtml]="'ORDER_FORM.TYPE.BOOK_HELP'|translate:{class:'buy', type:('GLOBAL.BID'|translate)}"></span>
                          </ng-container>
                          <ng-container *ngIf="order.operation_type === 'venta'">
                            <!-- It's Selling -->
                            <span *ngIf="company?.market.configuration.order.pre_book.sell" [innerHtml]="'ORDER_FORM.TYPE.PREBOOK_HELP'|translate:{class:'sell', type:('GLOBAL.SELL'|translate)}"></span>
                            <ng-container *ngIf="!company?.market.configuration.order.pre_book.sell">
                              <!-- NO prebook for selling -->
                              <span [innerHtml]="'ORDER_FORM.TYPE.BOOK_HELP'|translate:{class:'sell', type:('GLOBAL.SELL'|translate)}"></span>
                              <span *ngIf="company?.market.configuration.company.authorized_buyers.enabled">{{ 'ORDER_FORM.TYPE.BOOK_HELP_1'|translate }}</span>
                              <span *ngIf="!company?.market.configuration.company.authorized_buyers.enabled">{{ 'ORDER_FORM.TYPE.BOOK_HELP_2'|translate }}</span>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                      </ng-template>
                    </span>
                  </div>
                </fieldset>
                <alert *ngIf="companyHasNoLocations()" type="danger">
                  <span [innerHtml]="'ORDER_FORM.NO_COMPANY_LOCATIONS'|translate: {companyId: company.id}"></span>
                </alert>
              </div>
            </div>

            <div class="row"
            [class.hidden]="!order.operation_type ||
            delivery_types.length === 0 ||
            (delivery_types.length === 1 && delivery_types[0].values.length === 1)"><!-- Delivery type -->
              <div class="col-md-6">
                <div class="form-group" hasError [hasErrorOn]="mode === 'create'? 'submit': 'always'">
                  <label class="control-label">{{ delivery_type_label }}</label>
                  <select class="form-control" name="delivery_type" [ngModel]="order.business_detail.delivery.delivery_type" (ngModelChange)="changeDeliveryType($event)" #delivery_type="ngModel" required [compareWith]="compareId" [disabled]="mergeMode || editMode">
                    <option [ngValue]="undefined" [selected]="true" [disabled]="true">
                      {{ delivery_type_placeholder }}
                    </option>
                    <ng-container *ngIf="delivery_types.length > 1">
                      <optgroup label="{{ group.key.name }}" *ngFor="let group of delivery_types">
                        <option *ngFor="let dt of group.values" [ngValue]="dt" [disabled]="!dt.active">{{ dt.name }}</option>
                      </optgroup>
                    </ng-container>
                    <ng-container *ngIf="delivery_types.length === 1">
                      <option *ngFor="let dt of delivery_types[0].values" [ngValue]="dt" [disabled]="!dt.active">{{ dt.name }}</option>
                    </ng-container>
                  </select>
                  <error-list></error-list>
                </div>
              </div>
            </div>

            <div class="row"
            [class.hidden]="company?.activity.broker ||
            fetchingLocations ||
            brokers.length < 1 ||
            (company?.market.configuration.location.enabled && order.operation_type === 'compra' && !locations.length) ||
            isAuction"><!-- Broker --> <!-- Se oculta si isAuction es true, ya que el escenario no está soportado -->
              <div class="col-md-6">
                <div class="form-group" hasError>
                  <label class="control-label">
                    {{ 'ORDER_FORM.BROKER.LABEL'|translate }}
                  </label>
                  <select class="form-control" name="broker" [ngModel]="order.broker_company" (ngModelChange)="changeBroker($event)"
                    #broker="ngModel" [disabled]="editMode" [compareWith]="compareId">
                    <option [ngValue]="null" [selected]="true">
                      {{ 'ORDER_FORM.BROKER.PLACEHOLDER'|translate }}
                    </option>
                    <option *ngFor="let brok of brokers" [ngValue]="brok">{{ brok.name }}</option>
                  </select>
                  <span class="small help-block" *ngIf="order.broker_company">
                    <span [innerHtml]="'ORDER_FORM.BROKER.HELP'|translate"></span>
                  </span>
                </div>
              </div>
            </div>

            <div class="row"
            [class.hidden]="order.broker_company ||
            !scopeSelector ||
            fetchingLocations ||
            (company?.market.configuration.location.enabled && order.operation_type === 'compra' && !locations.length)"><!-- Order scope -->
              <div class="col-md-6">
                <fieldset [disabled]="mergeMode || editMode">
                  <div class="form-group" hasError>
                    <label class="control-label">
                      {{ 'ORDER_FORM.SCOPE.LABEL'|translate }}
                    </label>
                    <div class="btn-group btn-group-agree btn-group-justified" data-toggle="buttons">
                      <label class="btn btn-default"
                      *ngIf="scopes.market"
                      [class.active]="order.scope === 'abierto'"
                      [class.disabled]="!company?.hasModule('market')">
                        <input type="radio" name="orderScope" id="order-scope-1" [(ngModel)]="order.scope" [value]="'abierto'" #orderScope="ngModel" (ngModelChange)="changeScope($event)">
                        <span>{{ 'ORDER_FORM.SCOPE.OPEN'|translate }}</span>
                      </label>
                      <label class="btn btn-default"
                      *ngIf="scopes.network"
                      [class.active]="order.scope === 'red'">
                        <input type="radio" name="orderScope" id="order-scope-3" [(ngModel)]="order.scope" [value]="'red'" (ngModelChange)="changeScope($event)">
                        <span>{{ 'ORDER_FORM.SCOPE.MY_NETWORK'|translate }}</span>
                      </label>
                      <label class="btn btn-default"
                      *ngIf="scopes.private"
                      [class.active]="order.scope === 'privado'">
                        <input type="radio" name="orderScope" id="order-scope-2" [(ngModel)]="order.scope" [value]="'privado'" (ngModelChange)="changeScope($event)">
                        <span>{{ 'ORDER_FORM.SCOPE.PRIVATE'|translate }}</span>
                      </label>
                      <label class="btn btn-default"
                      *ngIf="!isAuction && scopes.offline"
                      [class.active]="order.scope === 'offline'">
                        <input type="radio" name="orderScope" id="order-scope-4" [(ngModel)]="order.scope" [value]="'offline'" (ngModelChange)="changeScope($event)">
                        <span>{{ 'ORDER_FORM.SCOPE.OFFLINE'|translate }}</span>
                      </label>
                    </div>
                    <span class="small help-block"
                    [ngSwitch]="order.scope">
                      <span *ngSwitchCase="'abierto'"
                      [innerHtml]="(isAuction ? 'AUCTION_FORM.SCOPE.OPEN_HELP' : 'ORDER_FORM.SCOPE.OPEN_HELP')|translate"></span>
                      <span *ngSwitchCase="'privado'"
                      [innerHtml]="(isAuction ? 'AUCTION_FORM.SCOPE.PRIVATE_HELP' : 'ORDER_FORM.SCOPE.PRIVATE_HELP')|translate"></span>
                      <span *ngSwitchCase="'red'"
                      [innerHtml]="(isAuction ? 'AUCTION_FORM.SCOPE.MY_NETWORK_HELP' : 'ORDER_FORM.SCOPE.MY_NETWORK_HELP')|translate: {companyId: company.id}"></span>
                      <span *ngSwitchCase="'offline'"
                      [innerHtml]="'ORDER_FORM.SCOPE.OFFLINE_HELP'|translate"></span>
                    </span>
                    <!-- <span class="small help-block" *ngIf="!company?.hasModule('market')">
                      <span [innerHtml]="'ORDER_FORM.SCOPE.NO_OPEN_HELP'|translate"></span>
                    </span> -->
                  </div>
                </fieldset>
              </div>
            </div>

            <div class="row" [class.hidden]="!isAuction || companyHasNoLocations() || fetchingLocations"><!-- Auction start and duration -->
              <div class="col-sm-4">
                <div class="form-group" hasError [hasErrorOn]="mode === 'create'? 'submit': 'always'">
                  <label class="control-label">
                    {{ 'AUCTION_FORM.DATE.DATE'|translate }}
                  </label>
                  <div class="input-group">
                    <span class="input-group-addon">
                      <i class="material-symbols-rounded">date_range</i>
                    </span>
                    <input type="text"
                    name="auctionDate"
                    class="form-control"
                    bsDatepicker
                    placeholder="{{ 'GLOBAL.DATE_PLACEHOLDER'|translate }}"
                    container=".app-content"
                    [bsConfig]="{showWeekNumbers: false, isAnimated: true, dateInputFormat: 'DD/MM/YYYY'}"
                    [minDate]="minAuctionDate"
                    [(ngModel)]="auction_date"
                    [required]="isAuction"
                    (bsValueChange)="changeAuctionDuration()"
                    #auctionDate="ngModel" readonly>
                    <!-- <input type="date" min="2019-04-01" placeholder="" class="form-control"> -->
                  </div><!-- /btn-group -->
                  <error-list></error-list>
                  <!-- <span class="small help-block">
                    Help?
                  </span> -->
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group" hasError hasErrorOn="dirty">
                  <label class="control-label">
                    {{ 'AUCTION_FORM.DATE.TIME'|translate }}
                  </label>
                  <div class="input-group">
                    <span class="input-group-addon">
                      <i class="material-symbols-rounded">timer</i>
                    </span>
                    <input
                    [disabled]="auction_date == undefined"
                    type="time"
                    [attr.min]="company?.market.operational_range?.from + ':00'"
                    [attr.max]="company?.market.operational_range?.to + ':00'"
                    placeholder="" class="form-control"
                    name="auction_hour"
                    #auction_hour="ngModel"
                    [(ngModel)]="auction_hour_from"
                    (ngModelChange)="changeAuctionDuration()"
                    [required]="isAuction"
                    timeRange="{{ company?.market.operational_range?.from }}-{{ company?.market.operational_range?.to }}"
                    [futureTime]="auction_date|date:'yyyy-MM-dd'">
                  </div><!-- /btn-group -->
                  <error-list></error-list>
                  <span class="small help-block" *ngIf="company">
                    {{ 'AUCTION_FORM.DATE.TIME_HELP'|translate:{market:company?.market.name} }}
                  </span>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group" hasError>
                  <label class="control-label">
                    {{ 'AUCTION_FORM.DATE.DURATION'|translate }}
                  </label>
                  <select class="form-control" [(ngModel)]="auction_duration" name="auction_duration" (ngModelChange)="changeAuctionDuration()" [disabled]="auction_hour_from == undefined" [required]="isAuction">
                    <option [ngValue]="undefined" [selected]="true" [disabled]="true">{{ 'AUCTION_FORM.DATE.DURATION_PLACEHOLDER'|translate }}</option>
                    <option *ngFor="let duration of auction_allowed_durations" [ngValue]="duration.value" [disabled]="duration.disabled">{{ duration.value }} {{ 'AUCTION_FORM.DATE.MINUTES'|translate }}</option>
                  </select>
                  <error-list></error-list>
                  <span class="small help-block" [innerHtml]="'AUCTION_FORM.DATE.DURATION_HELP'|translate"></span>
                </div>
                <div class="checkbox flex-center-distributed">
                  <div>
                    <input type="checkbox" class="ios"
                    name="auction_extend_time" id="extendtime_id"
                    [disabled]="auction_hour_from == undefined"
                    [(ngModel)]="auction_extend_time" (ngModelChange)="changeAuctionExtendTime()">
                  </div>
                  <label for="extendtime_id" class="grow">{{ 'AUCTION_FORM.DATE.EXTEND'|translate }}</label>
                </div>
                <span class="small help-block" [innerHtml]="'AUCTION_FORM.DATE.EXTEND_HELP'|translate"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row step"
      [validateFormStep]="step01.validateFormStep &&
      !product.invalid &&
      isQualityCompleted"
      #step02="validateFormStep"
      [nextStep]="step03">
        <div class="col-xs-12">
          <div class="line"></div>
          <span class="step-indicator">
            <span></span>
            <i class="material-symbols-rounded">done</i>
          </span>
          <div class="container-fluid" style="padding-left: 0px;">
            <div class="row"><!-- Product -->
              <div class="col-md-6">
                <!-- <p class="strong">Detalle del producto</p> -->
                <div class="form-group" hasError>
                  <label class="control-label">
                    {{ 'GLOBAL.PRODUCT'|translate }}
                  </label>
                  <select class="form-control" name="product" [ngModel]="order.product" (ngModelChange)="changeProduct($event)"
                    #product="ngModel" required [compareWith]="compareId" [disabled]="mergeMode || editMode">
                    <option [ngValue]="undefined" [selected]="true" [disabled]="true">{{ 'GLOBAL.SELECT_PRODUCT'|translate }}</option>
                    <option *ngFor="let prod of products" [ngValue]="prod">{{ prod.name }}</option>
                  </select>
                  <error-list></error-list>
                  <a class="small"
                  (click)="hubSpotService.open()">{{ 'ORDER_FORM.PRODUCT.NOT_LISTED'|translate }}</a>
                </div>
              </div>
              <div class="col-md-6" id="order_images" *ngIf="order.product && company?.market.configuration.order.allow_media">
                <div class="form-group" hasError hasErrorOn='always'>
                  <label class="control-label">{{ 'ORDER_FORM.IMAGES.LABEL'|translate }}</label>
                  <file-input
                  drop-area="#order_images"
                  name="images[]"
                  inputId="images"
                  [(ngModel)]="order.new_media"
                  [files]="order.media"
                  [max-size]="5242880"
                  accept="image/*"
                  [max-files]="20"
                  #images="ngModel"
                  (delete)="deleteImage($event)"
                  [deletedFile]="deletedFile"></file-input>
                  <error-list></error-list>
                  <p class="help-block">{{ 'ORDER_FORM.IMAGES.HELP'|translate }}</p>
                </div>
              </div>
              <div class="col-sm-4" *ngIf="order.product?.attributes?.quality?.sustainable">
                <div class="checkbox flex-center-distributed">
                  <div>
                    <input type="checkbox" class="ios"
                    name="sustainable" id="sustainable"
                    [(ngModel)]="order.business_detail.sustainable">
                  </div>
                  <label for="sustainable" class="grow">{{ 'ORDER_FORM.PRODUCT.SUSTAINABLE'|translate }}</label>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="order.product"><!-- Quality specs -->
              <ng-container  *ngFor="let attribute of order.product?.attributes?.quality|pairs|orderBy:'1.order'">
                <div class="col-sm-4"
                *ngIf="attribute[0] != 'sustainable' || order.business_detail.sustainable">
                  <div class="form-group" hasError>
                    <label class="control-label">{{ attribute[1].name }}</label>
                      <textarea class="form-control"
                      *ngIf="attribute[1].element === 'textarea'"
                      name="quality_{{ attribute[0] }}"
                      [(ngModel)]="order.product_detail.quality[attribute[0]]"
                      [disabled]="mergeMode"
                      rows="6"
                      required
                      maxlength="512"
                      (ngModelChange)="qualityChanged()"></textarea>
                      <select class="form-control" *ngIf="attribute[1].element !== 'textarea'" name="quality_{{ attribute[0] }}" [(ngModel)]="order.product_detail.quality[attribute[0]]"
                        [compareWith]="compareId" [disabled]="mergeMode" #quality="ngModel"
                        (ngModelChange)="qualityChanged()">
                        <option *ngIf="attribute[1].empty" [ngValue]="attribute[1].empty">{{ attribute[1].empty.name }}</option>
                        <option *ngFor="let option of attribute[1].values | orderBy: (attribute[1].empty ? '' : 'name') ; let first = first" [ngValue]="option" [selected]="first">
                          {{ option.name }}
                        </option>
                      </select>
                  </div>
                </div>
              </ng-container>
              <div class="col-sm-4"
              *ngIf="order.operation_type === 'venta' || order.scope === 'offline'">
                <div class="checkbox flex-center-distributed">
                  <div>
                    <input type="checkbox" class="ios"
                    name="own_production" id="own_production"
                    [(ngModel)]="order.own_production">
                  </div>
                  <label for="own_production" class="grow">{{ 'ORDER.OWN_PRODUCED'|translate }}</label>
                </div>
              </div>
            </div>
           <ag-product-quality-info [order]="order" [proposal]="order" key="type">
           </ag-product-quality-info>
          </div>
        </div>
      </div>
      <div class="row step"
      [validateFormStep]="step02.validateFormStep &&
      price.valid &&
      quantity.valid &&
      deliveryDate.valid &&
      (!company?.market.configuration.location.enabled || (localidad.valid && order.operation_type === 'venta') || (location.valid && order.operation_type === 'compra')) &&
      (!company?.market.configuration.geolocation?.enabled || geolocations.valid) &&
      (!isAuction || isAuctionStepValid())"
      #step03="validateFormStep"
      [nextStep]="step04">
        <div class="col-xs-12">
          <div class="line"></div>
          <span class="step-indicator">
            <span></span>
            <i class="material-symbols-rounded">done</i>
          </span>

          <div class="container-fluid" style="padding-left: 0px;">
            <div class="row">
              <div class="col-sm-4"><!-- Price -->
                <div class="form-group" hasError>
                  <label class="control-label" *ngIf="!isAuction">
                    <span *ngIf="!order.broker_company">
                      {{ 'GLOBAL.PRICE'|translate }}
                    </span>
                    <span *ngIf="order.broker_company && order.operation_type === 'compra'">
                      {{ 'ORDER_FORM.PRICE.MAX'|translate }}
                    </span>
                    <span *ngIf="order.broker_company && order.operation_type === 'venta'">
                      {{ 'ORDER_FORM.PRICE.MIN'|translate }}
                    </span>
                  </label>
                  <label class="control-label" *ngIf="isAuction">
                    {{ (order.operation_type === 'compra' ? 'AUCTION_FORM.PRICE.MAX' : 'AUCTION_FORM.PRICE.MIN')|translate }}
                  </label>
                  <div ngModelGroup="priceGroup" #price="ngModelGroup">
                    <!--
                      [showType] estan deprecados. Se utiliza [types]
                    -->
                    <price-form
                    [types]="company?.market.configuration.order.business_detail.price.types"
                    [isAuction]="isAuction"
                    [price]="order.business_detail.price"
                    [currencies]="currencies"
                    [max]="maxPrice"
                    [min]="minPrice"
                    [units]="order.product?.quantity_units"
                    placeholder="0.00"
                    (priceTypeChange)="onChangePrice($event)"></price-form>
                  </div>
                  <span class="small help-block">
                    <span *ngIf="order.operation_type === 'compra' && (order.broker_company || isAuction)" [innerHtml]="'ORDER_FORM.PRICE.MAX_HELP'|translate"></span>
                    <span *ngIf="order.operation_type === 'venta' && (order.broker_company || isAuction)" [innerHtml]="'ORDER_FORM.PRICE.MIN_HELP'|translate"></span>
                    <span [innerHTML]='company?.market.configuration.order.business_detail.price.tax_label'></span>
                  </span>
                </div>
              </div>

              <div class="col-sm-4" *ngIf="isAuction">
                <div class="form-group" hasError>
                  <label class="control-label">
                    {{ (order.operation_type === 'compra' ? 'AUCTION_FORM.STEP.LABEL_BUY' : 'AUCTION_FORM.STEP.LABEL_SELL')|translate }}
                  </label>
                  <input type="number" name="auction_step" [min]="auctionStep"
                  [max]="9999999"
                  [step]="auctionStep" [(ngModel)]="order.auction.step" placeholder="" class="form-control" required>
                  <span *ngIf="order.operation_type === 'venta'" class="small help-block" [innerHtml]="'AUCTION_FORM.STEP.SELL_HELP'|translate"></span>
                  <span *ngIf="order.operation_type === 'compra'" class="small help-block" [innerHtml]="'AUCTION_FORM.STEP.BUY_HELP'|translate"></span>
                  <error-list></error-list>
                </div>
              </div>

              <div class="col-sm-4"><!-- Quantity -->
                <!-- (qType.invalid && (qType.dirty || qType.touched || form.submitted)) || -->
                <div class="form-group" hasError>
                  <label class="control-label">
                    {{ order.product?.attributes?.order_quantity_label[order.operation_type] || order.product?.attributes?.order_quantity_label || 'GLOBAL.QUANTITY'|translate }}
                  </label>
                  <div class="input-group">
                    <div dropdown agSelect name="quantityType" [(ngModel)]="order.business_detail.quantity.type" class="input-group-btn" [isDisabled]="forceQuantity || isAuction" *ngIf="quantityTypes.length > 1">
                      <button dropdownToggle type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                        {{ order.business_detail.quantity.type?.name }}
                        <span class="caret"></span>
                      </button>
                      <ul *dropdownMenu class="dropdown-menu">
                        <li *ngFor="let q of quantityTypes" [agOption]="q"><a>{{ q.name }}</a></li>
                      </ul>
                    </div><!-- /btn-group -->
                    <input type="number" placeholder="0" step="1" class="form-control" name="quantity" [(ngModel)]="order.business_detail.quantity.value"
                    #quantity="ngModel" required number [min]="minQuantity"
                    [max]="9999999">
                    <div dropdown agSelect name="quantityUnit" class="input-group-btn" [(ngModel)]="order.business_detail.quantity.unit"
                    [class.single-unit]="order.product?.quantity_units?.length <= 1">
                      <button dropdownToggle type="button" class="btn btn-default dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                        {{ order.business_detail.quantity?.unit.name_plural || order.business_detail.quantity?.unit.abbrev || order.business_detail.quantity?.unit.name }}
                        <span class="caret"></span>
                      </button>
                      <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
                        <li *ngFor="let u of order.product?.quantity_units" [agOption]="u"><a>{{ u.name_plural }}</a></li>
                      </ul>
                    </div><!-- /btn-group -->
                  </div><!-- /btn-group -->
                  <error-list></error-list>
                  <span class="small help-block" *ngIf="!isAuction && order.operation_type === 'compra' && !company?.activity.broker && company?.market.configuration.order.quantity_types.enabled">
                    <span *ngIf="!order.broker_company">
                      <span [innerHtml]="'ORDER_FORM.QUANTITY.HELP_0'|translate"></span>
                      <span class="buy">{{ 'GLOBAL.BID'|translate }}</span>
                      <span [innerHtml]="'ORDER_FORM.QUANTITY.HELP_1'|translate"></span>
                    </span>
                    <span *ngIf="order.business_detail.quantity.type?.id === 1" [innerHtml]="'ORDER_FORM.QUANTITY.FIXED_HELP'|translate"></span>
                    <span *ngIf="order.business_detail.quantity.type?.id === 2" [innerHtml]="'ORDER_FORM.QUANTITY.MAX_HELP'|translate"></span>
                  </span>
                </div>
              </div>

              <div class="col-sm-4"><!-- Delivery period -->
                <div class="form-group" hasError [hasErrorOn]="mode === 'create'? 'submit': 'always'">
                  <label class="control-label">{{ company?.market.configuration.order.business_detail.delivery.range.label }}</label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="material-symbols-rounded">date_range</i>
                    </div>
                    <input type="text" name="deliveryDate" class="form-control" placeholder="{{ 'GLOBAL.RANGE_PLACEHOLDER'|translate }}"
                    placement="left"
                    bsDaterangepicker
                    container=".app-content"
                    [minDate]="today"
                    [bsConfig]="{adaptivePosition: true, showWeekNumbers: false, rangeInputFormat: 'DD/MM/YYYY'}"
                    (ngModelChange)="deliveryRangeChange()"
                    [(ngModel)]="order.business_detail.delivery.range"
                    required readonly
                    #deliveryDate="ngModel">
                  </div>
                  <error-list></error-list>
                  <a class="small" (click)="setDeliveryRange(30)">{{ company?.market.configuration.market_summary.available.label }}</a>
                  <p class="text-warning small"
                  *ngIf="traslatedRange">{{ 'MY_OPERATIONS_VIEW.BULK_REPUBLISH.TOOLTIP_DATE'|translate }}</p>
                  <span class="small help-block"
                  *ngIf="(company?.market.configuration.order.expiration_date?.enabled ||
                  (order.business_detail.price.type === 'to_be_fixed' && expirationForTBF)) &&
                  (order.business_detail.delivery.date_from)">
                    {{ 'ORDER_FORM.PERIOD.EXPIRATION_HELP_0'|translate }}
                    <b class="text-uppercase">{{ order.business_detail.delivery.date_from|date: ('GLOBAL.DATE_FORMAT.LG'|translate):undefined:currentLang }}</b>
                    {{ 'ORDER_FORM.PERIOD.EXPIRATION_HELP_1'|translate }}
                  </span>
                </div>
              </div>
            </div>
            <div class="row" [class.hidden]="!company?.market.configuration.geolocation?.enabled"><!-- Delivery place -->
              <div class="col-xs-12">
                <div class="form-group" hasError>
                  <label class="control-label">
                    {{ 'ORDER_FORM.DELIVERY_LOCATION.LABEL'|translate }}
                  </label>
                  <div>
                    <google-places name="geolocations" [(ngModel)]="order.business_detail.delivery.geolocations" #geolocations="ngModel" [required]="company?.market.configuration.geolocation?.enabled"></google-places>
                  </div>
                  <error-list></error-list>
                </div>
              </div>
            </div>
            <div class="row" [class.hidden]="!company?.market.configuration.location.enabled"><!-- Delivery place -->
              <div class="col-xs-12" [class.hidden]="(order.operation_type === 'compra' && !isPreOrder)">
                <div class="form-group" hasError>
                  <label class="control-label">
                    {{ 'ORDER_FORM.DELIVERY_LOCATION.LABEL'|translate }}
                  </label>
                  <div>
                    <ag-location-picker #locationPicker="agLocationPicker" name="localidad"
                    [ngModel]="order.business_detail.delivery.locations"
                    [market]="company?.market"
                    (ngModelChange)="changeLocations($event)" [arrayMinlength]="company?.market.configuration.location.enabled ? 1 : 0" type="multiple"
                    title="{{ 'ORDER_FORM.DELIVERY_LOCATION.TITLE'|translate }}"
                    [disabled]="mergeMode" #localidad="ngModel"></ag-location-picker>
                  </div>
                  <error-list></error-list>
                </div>
              </div>
              <div class="col-sm-4" [class.hidden]="(order.operation_type === 'venta' || (order.operation_type === 'compra' && isPreOrder))">
                <div class="form-group" hasError>
                  <label class="control-label">
                    {{ 'ORDER_FORM.DELIVERY_LOCATION.LABEL'|translate }}
                  </label>
                  <div *ngIf="fetchingLocations">
                    <spinner message="{{ 'ORDER_FORM.DELIVERY_LOCATION.LOADING'|translate }}"></spinner>
                  </div>
                  <multi-select [class.hidden]="fetchingLocations" name="location"
                  [source]="locations"
                  [(ngModel)]="order.business_detail.delivery.locations"
                  [arrayMinlength]="(company?.market.configuration.location.enabled && order.operation_type === 'compra') ? 1 : 0"
                  #location="ngModel" [withKey]="getGeoSelectionKey">
                    <ng-template let-item="item">
                      <span style="padding-left: .5em;" title="{{ item.zone.name }}" *ngIf="!item.location">
                        {{ item.zone.name }}
                      </span>
                      <span style="padding-left: .5em;" title="{{ item.location.name }}" *ngIf="item.location">
                        {{ item.location.name }} - {{ item.location.levels[2]? '(' + item.location.levels[2].name + ')' : '' }}
                      </span>
                    </ng-template>
                  </multi-select>
                  <error-list></error-list>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row step"
      [validateFormStep]="step03.validateFormStep &&
      step04Group.valid"
      #step04="validateFormStep"
      [nextStep]="step05">
        <div class="col-xs-12">
          <div class="line"></div>
          <span class="step-indicator">
            <span></span>
            <i class="material-symbols-rounded">done</i>
          </span>
          <div class="container-fluid" style="padding-left: 0px;"
          ngModelGroup="step04"
          #step04Group="ngModelGroup">
            <div class="row">
              <div class="col-sm-4"
              *ngIf="company?.market.configuration.company.authorized_buyers.enabled &&
              !company?.market.configuration.order.allow_negotiation_request &&
              order.operation_type === 'venta' &&
              !['privado', 'offline'].includes(order.scope)">
                <div class="form-group" hasError>
                  <label class="control-label">
                    {{ 'ORDER_FORM.AUTHORIZED_BUYERS.LABEL'|translate }}
                  </label>
                  <alert *ngIf="!counterparts.length && !fetchingBuyers" type="danger">
                    <span [innerHtml]="'ORDER_FORM.AUTHORIZED_BUYERS.ERROR_EMPTY_LOCATION'|translate"></span>
                  </alert>
                  <alert *ngIf="!order.business_detail.authorized_buyers.length && counterparts.length && !fetchingBuyers"
                    type="danger">
                    <span [innerHtml]="'ORDER_FORM.AUTHORIZED_BUYERS.ERROR_EMPTY_SELECTION'|translate"></span>
                  </alert>
                  <div *ngIf="fetchingBuyers">
                    <spinner message="{{ 'ORDER_FORM.AUTHORIZED_BUYERS.LOADING'|translate }}"></spinner>
                  </div>
                  <multi-select
                  [class.hidden]="fetchingBuyers"
                  name="buyers"
                  [source]="counterparts"
                  [(ngModel)]="order.business_detail.authorized_buyers"
                  [arrayMinlength]="(!company?.market.configuration.order.allow_negotiation_request && order.operation_type === 'venta' && company?.market.configuration.company.authorized_buyers.enabled) ? 1 : 0"
                  [withKey]="getId"
                  #buyers="ngModel">
                    <ng-template let-item="item">
                      <div class="flex-center-distributed">
                        <company-logo [company]="item"></company-logo>
                        <span class="grow" style="padding-left: .5em;" title="{{ item.name }}">
                          {{ item.name }}
                          <span *ngIf="item.fiscal_id.value" class="small text-muted">- <fiscal-id [value]="item.fiscal_id"></fiscal-id></span>
                        </span>
                      </div>
                    </ng-template>
                  </multi-select>
                  <error-list></error-list>
                  <span class="small help-block" *ngIf="counterparts.length > 0 && !fetchingBuyers">
                    {{ (isAuction ? 'AUCTION_FORM.AUTHORIZED_BUYERS.HELP' : 'ORDER_FORM.AUTHORIZED_BUYERS.HELP')|translate }}
                  </span>
                </div>
              </div>
              <div class="col-xs-12"
              [class.col-sm-8]="order.scope === 'offline'"
              *ngIf="['privado', 'offline'].includes(order.scope) &&
              step03.validateFormStep">
                <div class="form-group" hasError>
                  <label class="control-label">
                    {{ (order.scope === 'offline' ? 'GLOBAL.COUNTERPART' : 'GLOBAL.COUNTERPARTS')|translate }}
                  </label>
                  <selectize
                  *ngIf="!order.private_companies?.length || order.scope === 'privado'"
                  required
                  [searchURL]="companyService.companySolver((order.scope === 'privado' ? order.business_detail.delivery.locations : []), order.operation_type, company.id)"
                  [(ngModel)]="order.private_companies"
                  [reloadOnEvent]="reloadSelectize"
                  name="privateCompaniesSelect"
                  placeholder="{{ 'GLOBAL.ENTER_COMPANY'|translate }}"></selectize>
                  <div class="editable-pill"
                  *ngIf="order.private_companies.length === 1 && order.scope === 'offline'">
                    <button type="button" class="close"
                    (click)="cleanPrivateCompanies()"><span aria-hidden="true">&times;</span></button>
                    <company-info [company]="order.private_companies[0]"></company-info>
                  </div>
                  <error-list></error-list>
                  <ng-container [ngSwitch]="order.operation_type"
                  *ngIf="order.scope === 'privado'">
                    <span class="small help-block" *ngSwitchCase="'venta'" [innerHtml]="'PRIVATE_COMPANIES_SELECTOR.HELP_BUY'|translate"></span>
                    <span class="small help-block" *ngSwitchCase="'compra'" [innerHtml]="'PRIVATE_COMPANIES_SELECTOR.HELP_SELL'|translate"></span>
                  </ng-container>
                </div>
                <!-- Represented Companies -->
                <div class="form-group" hasError
                *ngIf="order.private_companies[0]?.activity.broker &&
                      order.scope === 'offline'">
                  <label class="control-label">
                    {{ "ORDER_FORM.COMPANY_REPRESENTED_BY" | translate }}
                    <small class="text-muted">({{ 'GLOBAL.OPTIONAL'|translate }})</small>
                  </label>
                  <selectize
                  *ngIf="order.private_companies[0]"
                  [searchURL]="companyService.getCompanies(company.id, {
                    all: true,
                    brokers: false,
                    excluded_ids: [company.id]
                  })"
                  [(ngModel)]="order.represented_counterpart"
                  [reloadOnEvent]="reloadSelectize"
                  name="representedCompaniesSelected"
                  placeholder="{{ 'GLOBAL.ENTER_COMPANY'|translate }}"></selectize>
                  <error-list></error-list>
                  <ng-container [ngSwitch]="order.operation_type"
                  *ngIf="order.scope === 'privado'">
                    <span class="small help-block" *ngSwitchCase="'venta'" [innerHtml]="'PRIVATE_COMPANIES_SELECTOR.HELP_BUY'|translate"></span>
                    <span class="small help-block" *ngSwitchCase="'compra'" [innerHtml]="'PRIVATE_COMPANIES_SELECTOR.HELP_SELL'|translate"></span>
                  </ng-container>
                </div>
              </div>

              <div class="col-md-4" *ngIf="company?.market.configuration.location.enabled && order.operation_type === 'venta'">
                <div class="form-group" hasError>
                  <label class="control-label">
                    {{ 'GLOBAL.ORIGIN'|translate }} <small class="text-muted">({{ 'GLOBAL.OPTIONAL'|translate }})</small>
                  </label>
                  <ng-template #locationTemplate let-item="item">
                    {{ item.name }} {{ item.levels[2]? '(' + item.levels[2].name + ')' : '' }} - {{ item.levels[1].name }}
                  </ng-template>
                  <search-input name="origin"
                  [(ngModel)]="order.business_detail.origin"
                  [search]="locationService.locationSolver(company.market.id)"
                  groupBy="levels.1.name" [itemTemplate]="locationTemplate" type="object">
                  </search-input>
                  <error-list></error-list>
                </div>
              </div>
              <div class="col-md-4" *ngIf="company?.market.configuration.geolocation.enabled && order.operation_type === 'venta'">
                <div class="form-group" hasError>
                  <label class="control-label">
                    {{ 'GLOBAL.ORIGIN'|translate }} <small class="text-muted">({{ 'GLOBAL.OPTIONAL'|translate }})</small>
                  </label>
                  <google-places name="origin" [(ngModel)]="order.business_detail.geo_origin" maxItems="1"></google-places>
                  <error-list></error-list>
                </div>
              </div>
              <div class="col-sm-4" *ngIf="order.product?.attributes?.harvest.enabled">
                <div class="form-group" hasError>
                  <label class="control-label">
                    {{ 'GLOBAL.CROP_YEAR'|translate }}
                  </label>
                  <select class="form-control" name="harvest"
                  [(ngModel)]="order.business_detail.harvest"
                  required [disabled]="mergeMode">
                    <option [ngValue]="null" [selected]="true" [disabled]="true">{{ 'GLOBAL.CROP_YEAR_PLACEHOLDER'|translate }}</option>
                    <option *ngFor="let crop of cropList" [ngValue]="crop">{{ crop }}</option>
                  </select>
                  <error-list></error-list>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group" hasError>
                  <label class="control-label">{{ 'GLOBAL.PAYMENT_CONDITION'|translate }}</label>
                  <select class="form-control" name="paymentCondition" [ngModel]="order.payment_detail.payment_condition"
                  (ngModelChange)="changePaymentCondition($event)"
                  [compareWith]="compareId" required>
                    <option [ngValue]="undefined" [selected]="true" [disabled]="true">{{ 'PAYMENT_CONDITION_INPUT.SELECT_TERMS'|translate }}</option>
                    <option *ngFor="let pc of paymentConditions" [ngValue]="pc"> {{ pc.name }} </option>
                  </select>
                  <error-list></error-list>
                </div>
              </div>
              <div class="col-sm-4" *ngIf="order.payment_detail.payment_condition?.slug === 'other'">
                <div class="form-group" hasError>
                  <label class="control-label">
                    {{ 'ORDER_FORM.ALTERNATIVE_CONDITION.LABEL'|translate }}
                  </label>
                  <input type="text" name="paymentOther" class="form-control"
                  maxlength="256"
                  [(ngModel)]="order.payment_detail.other" required>
                  <error-list></error-list>
                </div>
              </div>
              <div class="col-sm-4" *ngIf="order.payment_detail.payment_condition?.slug === 'certain-date'">
                <div class="form-group" hasError>
                  <label class="control-label">
                    {{ 'GLOBAL.DATE'|translate }}
                  </label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="material-symbols-rounded">date_range</i>
                    </div>
                    <input type="text" name="paymentDate" class="form-control" bsDatepicker
                    placeholder="{{ 'GLOBAL.DATE_PLACEHOLDER'|translate }}"
                    container=".app-content"
                    [bsConfig]="{showWeekNumbers: false, isAnimated: true, dateInputFormat: 'DD/MM/YYYY'}"
                    [minDate]="minPaymentDate" [(ngModel)]="order.payment_detail.date"
                    required
                    readonly>
                  </div>
                  <error-list></error-list>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label class="control-label">{{ 'GLOBAL.COMMISSION'|translate }}
                    <small class="text-muted">({{ 'GLOBAL.OPTIONAL'|translate }})</small>
                  </label>
                  <div class="input-group">
                    <input type="number" class="form-control" name="commission"
                    [min]="0" [max]="100" step="0.01"
                    [(ngModel)]="order.commission" placeholder="0.00">
                    <span class="input-group-addon">%</span>
                  </div>
                </div>
              </div>
              <div class="col-sm-4" *ngIf="order.product?.attributes?.ports">
                <div class="form-group" hasError>
                  <label class="control-label">
                    {{ 'ORDER_FORM.PORT.LABEL'|translate }}
                  </label>
                  <select class="form-control" name="port" [(ngModel)]="order.business_detail.port" [compareWith]="compareId"
                  required (ngModelChange)="changePort($event)">
                    <option [ngValue]="undefined" [selected]="true" [disabled]="true">
                      {{ 'ORDER_FORM.PORT.PLACEHOLDER'|translate }}
                    </option>
                    <option *ngFor="let port of order.product?.attributes?.ports" [ngValue]="port">{{ port.name }}</option>
                  </select>
                  <error-list></error-list>
                </div>
              </div>
              <div class="col-sm-4" *ngIf="order.product?.attributes?.ports">
                <div class="form-group" hasError>
                  <label class="control-label">
                    {{ 'ORDER_FORM.BERTH.LABEL'|translate }}
                  </label>
                  <select class="form-control" name="berth"
                  [(ngModel)]="order.business_detail.berth" [compareWith]="compareId"
                  required (ngModelChange)="changeBerth($event)">
                    <option [ngValue]="undefined" [selected]="true" [disabled]="true">
                      {{ 'ORDER_FORM.BERTH.PLACEHOLDER'|translate }}
                    </option>
                    <option *ngFor="let berth of order.business_detail.port?.berths" [ngValue]="berth">{{ berth.name }}</option>
                  </select>
                  <error-list></error-list>
                </div>
              </div>
              <div class="col-sm-4" *ngIf="order.business_detail.berth?.slug === 'otro'">
                <div class="form-group" hasError>
                  <label class="control-label">
                    {{ 'ORDER_FORM.ALTERNATIVE_BERTH.LABEL'|translate }}
                  </label>
                  <input type="text" name="berthOther" class="form-control"
                  maxlength="256"
                  [(ngModel)]="order.business_detail.other_berth"
                  required>
                  <error-list></error-list>
                </div>
              </div>
              <div class="col-sm-4" *ngIf="order.product?.attributes?.loading_rates">
                <div class="form-group" hasError>
                  <label class="control-label">
                    {{ 'ORDER_FORM.LOADING_RATE.LABEL'|translate }}
                  </label>
                  <div [class.input-group]="order.product?.attributes?.loading_rates?.metrics">
                    <input type="number" step="1" class="form-control" name="loading_rate" [(ngModel)]="order.business_detail.loading_rate.value"
                    [max]="99999999"
                    required
                    [placeholder]="'ORDER_FORM.LOADING_RATE.PLACEHOLDER'|translate"
                    *ngIf="!order.product?.attributes?.loading_rates?.values">

                    <span *ngIf="order.product?.attributes?.loading_rates?.values">{{ order.business_detail.loading_rate.value }}</span>

                    <div class="input-group-btn" *ngIf="order.product?.attributes?.loading_rates?.metrics">
                      <div dropdown agSelect name="loading_rate_metric" class="btn-group"
                      [(ngModel)]="order.business_detail.loading_rate.metric" (ngModelChange)="changeLoadingRateMetric($event)">
                        <button dropdownToggle type="button" class="btn btn-default dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                          {{ order.business_detail.loading_rate?.metric?.name }}
                          <span class="caret"></span>
                        </button>
                        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
                          <li *ngFor="let metric of order.product?.attributes?.loading_rates?.metrics" [agOption]="metric">
                            <a>{{ metric.name }}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <error-list></error-list>
                </div>
              </div>
              <div class="col-sm-4" *ngIf="order.business_detail.loading_rate?.metric?.slug === 'otra'">
                <div class="form-group" hasError>
                  <label class="control-label">
                    {{ 'ORDER_FORM.ALTERNATIVE_LOADING_RATE.LABEL'|translate }}
                  </label>
                  <input type="text" name="loadingRateOther" class="form-control"
                  maxlength="256"
                  [(ngModel)]="order.business_detail.loading_rate.other"
                  required>
                  <error-list></error-list>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row step"
      [validateFormStep]="step04.validateFormStep &&
      step05Group.valid"
      #step05="validateFormStep">
        <div class="col-xs-12">
          <span class="step-indicator">
            <span></span>
            <i class="material-symbols-rounded">done</i>
          </span>

          <div class="container-fluid" style="padding-left: 0px;"
          ngModelGroup="step05"
          #step05Group="ngModelGroup">
            <div class="row">
              <div class="col-sm-4" *ngIf="company?.market.configuration.order.arbitration_chamber.enabled">
                <div class="form-group">
                  <label class="control-label">
                    {{ 'ORDER_FORM.ARBITRATION_CHAMBER.LABEL'|translate }}
                    <small class="text-muted">({{ 'GLOBAL.OPTIONAL'|translate }})</small>
                  </label>
                  <select class="form-control" name="chamber"
                  [(ngModel)]="order.arbitration_chamber" [compareWith]="compareId"
                  #chamber="ngModel">
                    <option [ngValue]="undefined" [selected]="true">{{ 'ORDER_FORM.ARBITRATION_CHAMBER.PLACEHOLDER'|translate }}</option>
                    <option *ngFor="let chamber of chambers" [ngValue]="chamber">{{ chamber.name }}</option>
                  </select>
                </div>
              </div>
            </div>

            <ng-container *ngIf="company?.market.configuration.order.contract.type === 'contract_clauses'"><!-- Contract -->
              <div class="row">
                <div class="col-xs-7 col-sm-8 col-md-9">
                  <div class="form-group">
                    <label class="control-label">{{ 'GLOBAL.CONTRACT'|translate }}</label>
                    <div class="small help-block">
                      <div [innerHtml]="'ORDER_FORM.CONTRACT.HELP'|translate"></div>
                      <div *ngIf="company?.market.configuration.order.document_translate"
                      [innerHtml]="'ORDER_FORM.CONTRACT.LANGUAGE_HELP'|translate"></div>
                    </div>
                  </div>
                </div>
                <div class="col-xs-5 col-sm-4 col-md-3 col-highlight"
                *ngIf="company?.market.configuration.order.document_translate">
                  <div class="form-group">
                    <label class="control-label">{{ 'GLOBAL.DOCUMENTS_LANGUAGE'|translate }}</label>
                    <select name="language" class="form-control" [(ngModel)]="order.language">
                      <option *ngFor="let lang of availableLanguages"
                      [ngValue]="lang.slug">{{ lang.name }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12">
                  <contract-clauses-form
                  #contract
                  [contract]="order.contract_detail"
                  [order]="order"
                  [language]="order.language"
                  [company]="company"
                  [negotiability]="order.negotiability"
                  [previousContract]="previous_contract"
                  [createOrder]="true">
                    <div ngModelGroup="allClauses"></div>
                  </contract-clauses-form>
                </div>
              </div>
            </ng-container>

            <div class="row"><!-- Observations -->
              <div class="col-xs-12">
                <div class="form-group" *ngIf="company?.market.configuration.order.observations?.enabled">
                  <label class="control-label">
                    {{ 'GLOBAL.OBSERVATIONS'|translate }} <small class="text-muted">({{ 'GLOBAL.OPTIONAL'|translate }})</small>
                  </label>
                  <!-- <textarea class="form-control" rows="6" name="obs"
                  maxlength="1024"
                  [(ngModel)]="order.general_observations" #obs="ngModel">
                  </textarea> -->
                  <quill-editor
                  name="obs"
                  [modules]="{toolbar: toolbarOptions}"
                  [(ngModel)]="order.general_observations"></quill-editor>
                  <span class="small help-block" [innerHtml]="'ORDER_FORM.OBSERVATIONS.HELP'|translate"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="container" *ngIf="fatalError">
  <div class="content-box">
    <div [innerHtml]="fatalError" class="text-danger"></div>
  </div>
</div>

<spinner *ngIf="sending" message="{{ 'ORDER_FORM.PUBLISHING'|translate }}" [block-ui]="true"></spinner>

<preview-order [(order)]="order" *ngIf="previewOn && !editMode" (dismiss)="hidePreview()" (accept)="create()" [currentLang]="currentLang"></preview-order>
<preview-order [(order)]="order" [editMode]="editMode" *ngIf="previewOn && editMode" (dismiss)="hidePreview()" (accept)="save()" [currentLang]="currentLang"></preview-order>

<!-- TODO: Analyze how to replace with the confirm directive -->
<agree-confirm
title="{{ 'ORDER_FORM.CANCEL_CONFIRM.TITLE'|translate }}"
body="{{ 'ORDER_FORM.CANCEL_CONFIRM.BODY'|translate }}"
confirm-button="{{ 'ORDER_FORM.CANCEL_CONFIRM.CONFIRM_BUTTON'|translate }}"
cancel-button="{{ 'ORDER_FORM.CANCEL_CONFIRM.CONTINUE_BUTTON'|translate }}"
#editCancel="agreeConfirm"></agree-confirm>
