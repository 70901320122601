import { AfterContentInit, Component, ContentChild, ContentChildren, EventEmitter, Input, OnDestroy, Output, QueryList, forwardRef } from '@angular/core';
import { NgModelGroup } from '@angular/forms';
import { Subscription, combineLatest } from 'rxjs';

import { Clause } from '../../../models/clause.model';
import { TrackedControlDirective } from '../../../ui/directives/tracked-control.directive';
import { TrackedGroupDirective } from '../../../ui/directives/tracked-group.directive';

@Component({
  selector: 'negotiable-group',
  templateUrl: './negotiable-group.component.html',
  styleUrls: ['./negotiable-group.component.scss']
})
export class NegotiableGroupComponent implements OnDestroy, AfterContentInit {

  @Input() public clause: Clause;
  @Input() public contractHasChanged: boolean = false;
  @Input() public createOrder: boolean;
  @Input() public direction: string;
  @Input() public formMode: boolean;
  @Input() public isNew: boolean = false;
  @Input() public negotiableMode: string;
  @Input() public negotiableValue: boolean;
  @Input() public showChanges: boolean;

  @Output() readonly onDiscardChanges = new EventEmitter();

  @ContentChild(forwardRef(() => NgModelGroup), { static: true })
  public modelGroup: NgModelGroup;

  @ContentChildren(forwardRef(() => TrackedControlDirective), { descendants: true })
  public trackedControls: QueryList<TrackedControlDirective>;

  @ContentChildren(forwardRef(() => TrackedGroupDirective), { descendants: true })
  public trackedGroups: QueryList<TrackedGroupDirective>;

  public hasChanges: boolean = false;
  public hasDirtyChanges: boolean = false;

  private subscriptions: Subscription[] = [];
  private changesSubs: Subscription[] = [];

  constructor() { }

  ngAfterContentInit(): void {
    this.setupChangeSubscriptions();

    this.subscriptions.push(combineLatest([
      this.trackedControls.changes,
      this.trackedGroups.changes
    ]).subscribe(() => {
      this.setupChangeSubscriptions();
    }));
  }

  private setupChangeSubscriptions(): void {
    this.cleanupChangeSubscriptions();

    const controls = this.trackedControls.toArray();
    const groups = this.trackedGroups.toArray();

    // el setTimeOut() se utiliza como "hack" para evitar el ExpressionChangedAfterItHasBeenCheckedError
    // https://blog.angularindepth.com/everything-you-need-to-know-about-the-expressionchangedafterithasbeencheckederror-error-e3fd9ce7dbb4
    setTimeout(() => {
      if (groups.length) {
        this.changesSubs.push(combineLatest(groups.map((group: TrackedGroupDirective) => {
          return group.hasChanges;
        })).subscribe((changes: boolean[]) => {
          this.hasChanges = changes.reduce((anyChanges, hasChanges) => {
            return anyChanges || hasChanges;
          }, false);
        }));
        this.changesSubs.push(combineLatest(groups.map((group: TrackedGroupDirective) => {
          return group.hasDirtyChanges;
        })).subscribe((changes: boolean[]) => {
          this.hasDirtyChanges = changes.reduce((anyChanges, hasDirtyChanges) => {
            return anyChanges || hasDirtyChanges;
          }, false);
        }));
      } else if (controls.length) {
        this.changesSubs.push(combineLatest(controls.map((group: TrackedControlDirective) => {
          return group.hasChanges;
        })).subscribe((changes: boolean[]) => {
          this.hasChanges = changes.reduce((anyChanges, hasChanges) => {
            return anyChanges || hasChanges;
          }, false);
        }));
        this.changesSubs.push(combineLatest(controls.map((group: TrackedControlDirective) => {
          return group.hasDirtyChanges;
        })).subscribe((changes: boolean[]) => {
          this.hasDirtyChanges = changes.reduce((anyChanges, hasDirtyChanges) => {
            return anyChanges || hasDirtyChanges;
          }, false);
        }));
      }
    });
  }

  public displayPrevious(label: string): string {
    let result = '';

    let controls = this.trackedControls.toArray();
    let groups = this.trackedGroups.toArray();

    if (controls.length) {
      result = controls.reduce((text: string, control: TrackedControlDirective) => {
        let t = this.strip(control.displayPrevious());
        return text + (t ? t + ' ' : '');
      }, '');
    }

    if (groups.length) {
      result = groups.reduce((text: string, group: TrackedGroupDirective) => {
        let t = group.displayPrevious();
        return text + (t ? t + ' ' : '');
      }, '');
    }

    return result ? label + this.strip(result) : '';
  }

  public displayInitial(label: string): string {
    let result = '';

    let controls = this.trackedControls.toArray();
    let groups = this.trackedGroups.toArray();

    if (controls.length) {
      result = controls.reduce((text: string, control: TrackedControlDirective) => {
        let t = this.strip(control.displayInitial());
        return text + (t ? t + ' ' : '');
      }, '');
    }

    if (groups.length) {
      result = groups.reduce((text: string, group: TrackedGroupDirective) => {
        let t = group.displayInitial();
        return text + (t ? t + ' ' : '');
      }, '');
    }

    return result ? label + this.strip(result) : '';
  }

  private strip(input: string): string {
    if (typeof input != 'string') return input;

    const regex = /(<br\s*\/?>|<\/p>)/gi;
    const output = input.replace(regex, '$1&nbsp;');

    let doc = new DOMParser().parseFromString(output, 'text/html');
    return doc.body.textContent || "";
  }

  discard(clause) {
    this.trackedControls.forEach(control => {
      control.discard();
    });
    this.trackedGroups.forEach(group => {
      group.discard();
    });

    this.onDiscardChanges.emit(clause);
  }

  private cleanupChangeSubscriptions(): void {
    this.changesSubs.forEach(sub => {
      sub.unsubscribe();
    });
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.cleanupChangeSubscriptions();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
